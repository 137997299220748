import createEntityReducer from '../createEntityReducer';

import {
  RES_CATEGORIES_FAIL,
  RES_CATEGORIES_SUCCESS,
  REQ_CATEGORIES,
} from './categories.constants';

export const CategoryEntityReducer = createEntityReducer(
  { key: 'categories', id: 'id' },
  [REQ_CATEGORIES, RES_CATEGORIES_SUCCESS, RES_CATEGORIES_FAIL]
);
