import { RSAA } from 'redux-api-middleware';
import {
  REQ_ARTICLES,
  RES_ARTICLES_SUCCESS,
  RES_ARTICLES_FAILURE,
  REQ_ARTICLE,
  RES_ARTICLE_SUCCESS,
  RES_ARTICLE_FAILURE,
} from './articles.constants';

export const doFetchArticles = (uniqueName) => {
  return {
    [RSAA]: {
      method: 'GET',
      endpoint: `/artist/${uniqueName}/articles`,
      headers: { 'Content-Type': 'application/json' },
      types: [REQ_ARTICLES, RES_ARTICLES_SUCCESS, RES_ARTICLES_FAILURE],
    },
  };
};

export const doFetchArticle = (uniqueName, articleId) => {
  return {
    [RSAA]: {
      method: 'GET',
      endpoint: `/artist/${uniqueName}/article/${articleId}`,
      headers: { 'Content-Type': 'application/json' },
      types: [REQ_ARTICLE, RES_ARTICLE_SUCCESS, RES_ARTICLE_FAILURE],
    },
  };
};
