import styled from 'styled-components';

export const CollectionHeaderImage = styled.img`
  display: block;
  border-radius: ${(p) => p.theme.radius * 2}px;
  max-width: ${(p) => p.theme.maxWidth.text};
  width: 100%;
  margin: 0 auto ${(p) => p.theme.padding * 2.5}px;
  /* object-fit: cover; */
`;

export const CollectionTitle = styled.h1`
  text-align: center;
  font-size: 1.3rem;
  margin-bottom: ${(p) => p.theme.padding / 2}px;
`;

export const CollectionSubtitle = styled.h2`
  text-align: center;
  font-size: 1rem;
  color: ${(p) => p.theme.foreground3};
  margin-bottom: ${(p) => p.theme.padding * 2.5}px;
`;

export const CollectionDescription = styled.section`
  line-height: 1.5rem;
  margin: 0 auto ${(p) => p.theme.padding * 2}px;
  max-width: ${(p) => p.theme.maxWidth.text};
`;
