import createEntityReducer from '../createEntityReducer';

import {
  RES_ARTICLES_FAILURE,
  RES_ARTICLES_SUCCESS,
  REQ_ARTICLES,
  REQ_ARTICLE,
  RES_ARTICLE_SUCCESS,
  RES_ARTICLE_FAILURE,
} from './articles.constants';

export const ArticlesEntityReducer = createEntityReducer(
  { key: 'articles', id: 'id' },
  [REQ_ARTICLES, RES_ARTICLES_SUCCESS, RES_ARTICLES_FAILURE],
  [REQ_ARTICLE, RES_ARTICLE_SUCCESS, RES_ARTICLE_FAILURE]
);

export const ArticleCollectionsEntityReducer = createEntityReducer(
  { key: 'collections', id: 'id' },
  [REQ_ARTICLES, RES_ARTICLES_SUCCESS, RES_ARTICLES_FAILURE]
);
