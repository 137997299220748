import React from 'react';
import { ListWrapper } from '../../layout';
import ContentItem from './ContentItem';
import { withSubscription } from '../../user';
import { Channel } from '../../common/types/Channel';
import { Video } from '../../common/types/Video';
import { User } from '../../common/types/User';

type Props = {
  videos: Array<Video>;
  channel: Channel;
  contentItems: any;
  user: User;
  entity: any;
  type: string;
  canAccess: Function;
  getRedirectRoute: Function;
};

const ContentList = ({
  contentItems,
  user,
  entity,
  type,
  canAccess,
  getRedirectRoute,
}: Props) => {
  return (
    <ListWrapper type={type}>
      {contentItems && contentItems.length > 0
        ? contentItems.map((contentItem) => {
            const location = getEntityLocation(entity, contentItem);
            return contentItem ? (
              <ContentItem
                to={location.to}
                as={location.as}
                or={getRedirectRoute(contentItem)}
                key={contentItem.id}
                contentItem={contentItem}
                entity={entity}
                isActive={canAccess(contentItem)}
                isLocked={!canAccess(contentItem)}
                type={type}
              />
            ) : null;
          })
        : null}
    </ListWrapper>
  );
};

function getEntityLocation(entity, contentItem) {
  switch (entity) {
    case 'article':
      return {
        to: `/article/[articleId]`,
        as: `/article/${contentItem.id}`,
      };
    default:
      return {
        to: `/video/[id]`,
        as: `/video/${contentItem.id}`,
      };
  }
}

export default withSubscription(ContentList);
