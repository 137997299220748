import { HelmetIntl, formatDate, ContentImages } from '../../common';
import {
  LayoutContainer,
  PageBody,
  Content,
  ContentDate,
  ContentTitle,
  ContentSubtitle,
  ContentPerformers,
  ContentBody,
} from '../../layout';
import { useChannel } from '../../user/user.hooks';
import Comments from '../../comments/components/Comments';

function SingleArticlePage({ article }) {
  const channel = useChannel();

  function renderHeader(post) {
    if (!post.headerImage) {
      return null;
    }

    return <img src={post.headerImage.url} alt={post.title} />;
  }

  return (
    <LayoutContainer>
      <HelmetIntl
        titleId="app.meta.article.single.title"
        descriptionId="app.meta.article.single.description"
        values={{
          channel: channel.name,
          article: article.title,
          image: article.coverImage ? article.coverImage.url : null,
        }}
      />
      <PageBody narrow>
        <Content>
          <ContentTitle>{article.title}</ContentTitle>
          <ContentSubtitle>{article.subtitle}</ContentSubtitle>
          {article.performerNames ? (
            <ContentPerformers>
              {article.performerNames.map((performer, i) => {
                return i > article.performerNames.length
                  ? performer + ', '
                  : performer;
              })}
            </ContentPerformers>
          ) : null}

          {article.showDate ? (
            <ContentDate>{formatDate(article.createdAt)}</ContentDate>
          ) : null}
          {renderHeader(article)}
          {article.coverImage && (
            <ContentImages images={[article.coverImage]} />
          )}
          <ContentBody
            className="prose text-foreground-primary"
            dangerouslySetInnerHTML={{ __html: article.bodyHtml }}
          />
          {article.hasCommentsEnabled && (
            <div className="max-w-screen-sm mx-auto mb-12">
              <h2 className="py-2 font-bold text-xl uppercase tracking-wider">
                Comments
              </h2>
              <Comments contentId={article.id} />
            </div>
          )}
        </Content>
      </PageBody>
    </LayoutContainer>
  );
}

export default SingleArticlePage;
