import { RSAA } from 'redux-api-middleware';
import {
  REQ_CATEGORIES,
  RES_CATEGORIES_SUCCESS,
  RES_CATEGORIES_FAIL,
} from './categories.constants';

export const doFetchCategories = (uniqueName) => {
  return {
    [RSAA]: {
      method: 'GET',
      endpoint: `/artist/${uniqueName}/categories`,
      headers: { 'Content-Type': 'application/json' },
      types: [REQ_CATEGORIES, RES_CATEGORIES_SUCCESS, RES_CATEGORIES_FAIL],
    },
  };
};
