import * as React from 'react';
import styled, { css } from 'styled-components';
import { media } from '../../layout/themes/mediaQueries';
import { getYouTubeId, createYouTubeThumbnailUrl } from '../../player';
import {
  ConditionalLink,
  sizeCloudinaryImage,
  Icon,
  FadeIn,
} from '../../common';
import { ContentLockLabel } from '../../layout';
import { Icons } from '../../common/components/Icon';
// import { Video } from '../../common';

// type Props = {
//   video: Video;
//   to: string;
//   isActive: boolean;
// };

export default function ContentItem(props) {
  const { contentItem, to, as, or, entity, type, isActive } = props;

  if (!contentItem) {
    return null;
  }

  return (
    <ContentTile type={type}>
      <ConditionalLink to={to} as={as} or={or} isActive={isActive}>
        {entity === 'article' && renderArticle(props)}
        {entity === 'video' && renderVideo(props)}
      </ConditionalLink>
    </ContentTile>
  );
}

function renderVideo(props) {
  const { contentItem, entity, isLocked } = props;
  let thumbnail = contentItem.thumbnail;
  if (typeof thumbnail === 'string') {
    thumbnail = sizeCloudinaryImage(thumbnail, 300);
  } else {
    thumbnail = createYouTubeThumbnailUrl(getYouTubeId(contentItem.url));
  }

  return (
    <ContentTileLayoutGrid>
      <FadeInStyled style={{ width: '100%' }}>
        {(onLoad) => (
          <Comp>
            {(entity === 'video' || entity === 'release') && (
              <img src={thumbnail} alt={contentItem.title} onLoad={onLoad} />
            )}

            {isLocked && (
              <ContentLockLabel>
                <Icon icon={Icons.lock} color="#ffffff" />
              </ContentLockLabel>
            )}
          </Comp>
        )}
      </FadeInStyled>
      <Details>
        <Title>{contentItem.title}</Title>
      </Details>
    </ContentTileLayoutGrid>
  );
}

function renderArticle(props) {
  const { contentItem, entity, isLocked } = props;
  return (
    <ContentTileLayoutList>
      <FadeInStyledArticle>
        {(onLoad) => (
          <Comp>
            {entity === 'article' && contentItem.coverImage && (
              <img
                src={sizeCloudinaryImage(contentItem.coverImage.url, 300)}
                alt={contentItem.title}
                onLoad={onLoad}
              />
            )}

            {isLocked && (
              <ContentLockLabel>
                <Icon icon={Icons.lock} color="#ffffff" />
              </ContentLockLabel>
            )}
          </Comp>
        )}
      </FadeInStyledArticle>
      <ArticleMeta>
        <Headline>{contentItem.title}</Headline>
        <Subheadline>{contentItem.subtitle}</Subheadline>
      </ArticleMeta>
    </ContentTileLayoutList>
  );
}

const Comp = styled.div`
  position: relative;
  line-height: 0;
  overflow: hidden;
  border-radius: 4px;
  width: 100%;
  transition: transform 250ms;
  transition-timing-function: cubic-bezier(${(p) => p.theme.cubicBezir});

  img {
    border-radius: 4px;
    overflow: hidden;
  }

  ${media.medium`  
    &:hover {
      transform: scale(1.25);
      z-index: 90;

      img {
        box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.4);
      }
    }
  `}
`;

const FadeInStyled = styled(FadeIn)``;

const FadeInStyledArticle = styled(FadeIn)``;

const GridTile = css`
  width: 50%;
  flex: 0 1 50%;
  box-sizing: border-box;
  position: relative;
  padding: 0 ${(p) => p.theme.padding}px 18px;

  div {
    width: 100%;
    img {
      width: 100%;
    }
  }

  ${media.medium`
    flex: 0 1 33.33%;
    width: 33.33%;
    
  `};

  ${media.large`
    flex: 0 1 25%;
  `};
`;

const ListTile = css`
  /* padding: ${(p) => p.theme.padding * 2}px; */

  div {
    img {
      width: 100%;
    }
  }
`;

const ContentTile = styled.div`
  ${(p) => (p.type === 'list' ? ListTile : GridTile)}
`;

const ContentTileLayoutList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 1px solid ${(p) => p.theme.hairline};
  padding: ${(p) => p.theme.padding * 2}px;
  /* align-items: stretch; */

  ${media.medium`
    flex-wrap: nowrap;
  `};
`;

const ContentTileLayoutGrid = styled.div`
  display: flex;
  flex-direction: column;
`;

const Details = styled.div`
  padding: 0 0px ${(p) => p.theme.padding}px;
`;

const Title = styled.div`
  font-weight: 500;
  margin-top: ${(p) => p.theme.padding}px;
  line-height: 1.4em;
  font-size: 14px;

  ${media.medium`
    font-size: 16px;
  `};

  ${media.large`
    font-size: 16px;
  `};

  ${media.xlarge`
    font-size: 18px;
  `};
`;

const ArticleMeta = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: ${(p) => p.theme.padding}px;

  ${media.medium`
    width: 70%;
    margin-left: ${(p) => p.theme.padding * 2}px;  
    margin-top: ${(p) => p.theme.padding}px;
  `}
`;

const Headline = styled.h2`
  font-size: 1.3rem;
`;

const Subheadline = styled.span`
  color: ${(p) => p.theme.foreground2};
`;
