import { selectReleaseById } from '../releases';
import { selectCollectionById } from '../videos/VideosCollection.redux';
import { selectArticleCollectionById } from '../articles';

import { AppState } from '../common/types/AppState';

export const selectCategoryById = (state, id) =>
  state.entities.categories.byId[id];

export const selectCategories = (state: AppState) =>
  state.entities.categories.allIds.map((id) => selectCategoryById(state, id));

export const selectCategoriesWithReleases = (state: AppState) => {
  const categories = selectCategories(state);
  categories.map((category) =>
    category.albums.map((release) => selectReleaseById(state, release))
  );
  return categories.filter((category) => category.albums.length > 0);
};

export const selectCategoriesWithCollections = (state: AppState) => {
  const categories = selectCategories(state);

  return categories
    .filter((category) => category.videoCollections.length > 0)
    .map((category, i) => {
      category.videoCollections.map((collectionId, i) => {
        return selectCollectionById(state, collectionId);
      });
      return category;
    });
};

export const selectCategoriesWithArticleCollections = (state: AppState) => {
  const categories = selectCategories(state);

  return categories
    .filter((category) => category.articleCollections.length > 0)
    .map((category, i) => {
      category.articleCollections.map((collectionId, i) => {
        return selectArticleCollectionById(state, collectionId);
      });
      return category;
    });
};
