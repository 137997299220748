import { useSelector } from 'react-redux';
import { HelmetIntl } from '../../common';
import { selectArticleCollections } from '../articles.selectors';
import { CollectionListContainer } from '../../common';
import { selectCategoriesWithArticleCollections } from '../../categories';
import LayoutContainer from '../../layout/LayoutContainer';
import { PageBody } from '../../layout/components/PageBody';
import CollectionList from '../../releases/components/CollectionList';
import { ListBlock, ListTitle } from '../../layout';
import { Channel } from '../../common/types/Channel';

type Props = {
  channel: Channel;
};

function ArticlesPage({ channel }: Props) {
  const articleCollections = useSelector(selectArticleCollections);
  const categories = useSelector(selectCategoriesWithArticleCollections);

  return (
    <LayoutContainer>
      <HelmetIntl
        titleId="app.meta.articles.title"
        descriptionId="app.meta.articles.description"
        values={{
          channel: channel.name,
        }}
      />
      <PageBody>
        {channel.counts.articleCategories
          ? categories.map((category) => (
              <CollectionListContainer
                key={category.id}
                title={category.title}
                entityIds={category.articleCollections}
                entity="articleCollection"
                truncate={5}
              />
            ))
          : null}

        {!categories.length && (
          <ListBlock>
            <ListTitle>Articles</ListTitle>
            <CollectionList
              // @ts-ignore
              collections={articleCollections}
              truncate={10000}
              entity={'articleCollection'}
            />
          </ListBlock>
        )}
      </PageBody>
    </LayoutContainer>
  );
}

export default ArticlesPage;
