import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { HelmetIntl } from '../../common';
import {
  selectArticleCollectionById,
  selectArticlesByCollectionId,
} from '../articles.selectors';
import LayoutContainer from '../../layout/LayoutContainer';
import { PageBody } from '../../layout/components/PageBody';
import ContentList from '../../videos/components/ContentList';
import * as S from './Articles.styles';
import { Channel } from '../../common/types/Channel';

type Props = {
  channel: Channel;
};

function ArticleCollectionPage({ channel }: Props) {
  const { query } = useRouter();
  let articleCollectionIdString = query.articleCollectionId;
  if (typeof articleCollectionIdString !== 'string') {
    articleCollectionIdString = articleCollectionIdString[0];
  }
  const articleCollectionId = parseInt(articleCollectionIdString, 10);

  const articleCollection = useSelector((state) =>
    selectArticleCollectionById(state, articleCollectionId)
  );
  const articles = useSelector((state) =>
    selectArticlesByCollectionId(state, articleCollectionId)
  );

  return (
    <LayoutContainer>
      <HelmetIntl
        titleId="app.meta.collection.single.title"
        descriptionId="app.meta.collection.single.description"
        values={{
          channel: channel.name,
          collection: articleCollection.title,
          image: articleCollection.imageurl ? articleCollection.imageUrl : null,
        }}
      />
      <PageBody isContainer>
        <S.CollectionHeaderImage
          src={articleCollection.imageUrl}
          alt={articleCollection.title}
        />
        <S.CollectionTitle>{articleCollection.title}</S.CollectionTitle>
        <S.CollectionSubtitle>
          {articleCollection.subtitle}
        </S.CollectionSubtitle>
        <S.CollectionDescription>
          {articleCollection.body}
        </S.CollectionDescription>
        {/* 
          // @ts-ignore */}
        <ContentList contentItems={articles} entity="article" type="list" />
      </PageBody>
    </LayoutContainer>
  );
}

export default ArticleCollectionPage;
